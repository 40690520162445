.bold {
  font-weight: 700;
}
.tooltip {
  background: #20194d;
  border-radius: 8px;
  padding: 10px 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* or 142% */

  letter-spacing: 0.02em;
}
.toolbarWrapper {
  background-color: #ffffff !important;
}

.purpleText {
  color: #6c5dd3;
}

.paper {
  border: none !important;
}
