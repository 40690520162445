.copyIcon {
  height: 24px;
}

.copyIconDisabled path {
  fill: rgba(0, 0, 0, 0.26) !important;
}

.disabledOption {
  color: rgba(0, 0, 0, 0.26);
}

.dateTime {
  font-size: 14px;
  color: #272522;
  margin: 4px 0px;
}
